import React from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-12 md:pt-20 border-t border-b border-gray-800">
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-16">
            <Carousel
              swipeable={true}
              infiniteLoop={true}
              showStatus={false}
              showThumbs={false}
              stopOnHover={false}
              interval={5000}
              useKeyboardArrows={true}
              autoPlay={true}
            >
              <div>
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fprima.jpeg?alt=media&token=2985d603-53ac-4739-9774-523f31c291ea"
                  }
                  width="1000"
                  height="2000"
                />
              </div>
              <div>
                <img
                  className="w-full h-full object-cover"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fseconda.jpeg?alt=media&token=c6f5d414-5154-49a0-b4bc-f678d8bbafd4"
                  }
                  width="1000"
                  height="2000"
                />
              </div>
              <div>
                <img
                  className="w-full h-full object-cover"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fterza.jpeg?alt=media&token=343ef29d-726c-4050-a65e-ece48e226a2c"
                  }
                  width="1000"
                  height="2000"
                />
              </div>
              <div>
                <img
                  className="w-full h-full object-cover"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fquarta.jpeg?alt=media&token=2ea8450f-de2d-4fa8-b8ab-534a0160a894"
                  }
                  width="1000"
                  height="2000"
                />
              </div>
            </Carousel>

            {/* <h1
              className="h2 mb-4"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-cta]"
            >
              Hai bisogno di aiuto?
            </h1>
            <p
              className="text-xl text-gray-400 mb-8"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-cta]"
            >
              Per qualsiasi dubbio o informazioni sui prodotti non esitare a
              contattarmi!
            </p>

            
            <div
              className="flex justify-center mb-8"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-cta]"
            >
              <Link
                className="btn text-white bg-purple-600 hover:bg-purple-700"
                to="contacts"
              >
                CONTATTI
              </Link>
            </div>

          
            <ul className="flex flex-wrap justify-center text-lg text-gray-400 -mx-2 -my-1">
              <li
                className="flex items-center mx-3 my-1"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-anchor="[data-aos-id-cta]"
              >
                <svg
                  className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
                <span>Assistenza h24</span>
              </li>
              <li
                className="flex items-center mx-3 my-1"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-anchor="[data-aos-id-cta]"
              >
                <svg
                  className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                </svg>
                <span>Servizi su misura per te!</span>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
