import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import PricingTables from "../partials/PricingTables";
import Faqs from "../partials/Faqs";
import Testimonials from "../partials/Testimonials";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import HeroAbout from "../partials/HeroAbout";

function Pricing() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroAbout
          pageTitle={"Personal Trainig"}
          pageSubtitle={
            "Nel mio centro tengo allenamenti singoli, a coppie e a gruppi di 3-4 persone. I miei allenamenti hanno una durata di circa un’ora.\
            Avrai inoltre la possibilità di utilizzare cyclette, tapis roulant, spaliere e tappetini anche prima e dopo il nostro allenamento."
          }
        />
        <PricingTables />
        {/* <Faqs /> */}
        {/* <Testimonials /> */}
        <Cta />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Pricing;
