import React, { useState } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export const Maps = ({ google }) => {
  const [showWindow, setShow] = useState(false);
  return (
    <Map
      style={{ width: '100%', height: '500px', position: "relative" }}
      google={google}
      zoom={18}
      initialCenter={{
        lat: 45.60893,
        lng: 9.20616,
      }}
    >
      <Marker
        title={"RS Trainig"}
        name={"Quartier Generale"}
        position={{
          lat: 45.60893,
          lng: 9.20616,
        }}
        onClick={() =>
          window.open(
            "https://www.google.com/maps/dir//Via+Santa+Liberata,+9,+20832+Desio+MB/@45.6090792,9.2040061,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786bc200be5b17d:0x584cc0a0b4ff9608!2m2!1d9.2061948!2d45.6090792"
          )
        }
        onMouseover={() => setShow(true)}
      />

      <InfoWindow
        visible={showWindow}
        position={{
          lat: 45.60893,
          lng: 9.20616,
        }}
        onClose={() => setShow(false)}
      >
        <div className="pb-1">
          <h1 className="text-black h4">RS TRAINING</h1>
          <p className="text-black">
            Clicca{" "}
            <a
              href="https://www.google.com/maps/dir//Via+Santa+Liberata,+9,+20832+Desio+MB/@45.6090792,9.2040061,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786bc200be5b17d:0x584cc0a0b4ff9608!2m2!1d9.2061948!2d45.6090792"
              className="text-red-500 hover:underline"
            >
              qui{" "}
            </a>
            per ottenere indicazioni stradali!
          </p>
        </div>
      </InfoWindow>
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyC_ljGuAnrXXhrow3BofrCGQR-YBsZFlOs",
})(Maps);
