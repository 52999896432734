import React from "react";

function Target() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2" data-aos="fade-up">
              Il mio Centro Fitness!
            </h2>
            {/* <p
              className="text-xl text-gray-400 mt-10 md:mx-10 mx-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Tra i principali servizi vi è sicuramente la possibilità di
              allenarsi con me nel mio centro fitness.
            </p> */}
          </div>

          {/* Items */}
          <div className="grid gap-20" data-aos-id-target>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-anchor="[data-aos-id-target]"
              >
                <img
                  className="mx-auto md:max-w-none"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fcollage.png?alt=media&token=bc068503-8c65-43b1-93a2-bfb943e8f62b"
                  }
                  width="540"
                  height="520"
                  alt="Features 02"
                />
                <p
                  className="text-l italic text-gray-400 mt-10 md:mx-10 mx-5 px-16 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {`Sede Desio: Via Santa Liberata 9, Desio (MB)`}
                </p>
                <p
                  className="text-l italic text-gray-400 mt-10 md:mx-10 mx-5 px-16 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {`Sede Giussano: Via 4 Novembre 49, Giussano (MB)`}
                </p>
              </div>

              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div
                    className="font-dancing text-2xl text-purple-600 mb-2"
                    data-aos="fade-left"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    Inizia oggi il tuo cambiamento!
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="200"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-purple-600">.</span> PALESTRA
                    </h4>
                    <p className="text-lg text-gray-400">
                      Nel mio centro potrai allenarti con bilancieri, manubri e
                      kettlebell ma anche con diversi macchinari come Leg Press,
                      Abduction e Pectoral Machine ecc... Prima e dopo
                      l'allenamento c'è la possibilità di rimanere in palestra
                      utilizzando i diversi tapis roulant, cyclette e spalliere.
                    </p>
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="400"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-teal-500">.</span> LEZIONI SINGOLE,
                      A COPPIE E DI GRUPPO
                    </h4>
                    <p className="text-lg text-gray-400">
                      A seconda dei vostri obiettivi struttureremo il piano
                      migliore. {"\n"} Avendo a disposizione diversi attrezzi ci
                      sarà anche la possibilità di allenarsi a coppie o in
                      gruppi composti da massimo 4 persone.
                    </p>
                  </div>
                  {/* <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="600"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      <span className="text-pink-500">.</span> LEZIONI DI GRUPPO
                    </h4>
                    <p className="text-lg text-gray-400">
                      Avendo a disposizione diversi macchinari, bilancieri,
                      manubri e kettlebell, offro la possibilità di allenarsi in
                      gruppo: max 3/4 persone. Il lavoro sarà strutturato a
                      stazioni, ognuno con il proprio esercizio e volume.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Target;
