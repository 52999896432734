export const months = [
  "Gennaio",
  "Febbbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export const getFullDate = (day, month, year) => {
  return `${day} ${months[month]} ${year}`;
};
