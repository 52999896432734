import { getAllByLabelText } from "@testing-library/dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// import Dropdown from "../utils/Dropdown";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  // useEffect(() => {
  //   const clickHandler = ({ target }) => {
  //     // alert(target);
  //     if (!mobileNavOpen || mobileNav.current.contains(target)) return;
  //     setMobileNavOpen(false);
  //   };
  //   document.addEventListener("click", clickHandler);
  //   return () => document.removeEventListener("click", clickHandler);
  // });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img
                // data-aos="fade-in"
                src={require("../images/icons/headerlogo.png")}
                className="w-40 mt-4"
              ></img>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Chi sono
                </Link>
              </li>
              <li>
                <Link
                  to="/personaltraining"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Personal
                </Link>
              </li>
              <li>
                <Link
                  to="/homegym"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Home Gym
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Contatti
                </Link>
              </li>
              {/* 1st level: hover */}
              {/* <Dropdown title="Support">
                <li>
                  <Link
                    to="/contact"
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                  >
                    Contatti
                  </Link>
                </li>
                <li>
                  <Link
                    to="/help"
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                  >
                    Help center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/404"
                    className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight"
                  >
                    404
                  </Link>
                </li>
              </Dropdown> */}
            </ul>

            {/* implement this in future builds with firebase backend */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {process.env.NODE_ENV === "development" && (
                <>
                  <li>
                    <Link
                      to="/signin"
                      className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                    >
                      Accedi
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/signup"
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3"
                    >
                      Registrati
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              className={`hamburger`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link
                    to="/about"
                    onClick={() => setMobileNavOpen(false)}
                    className="flex text-gray-300 hover:text-gray-200 py-2"
                  >
                    Chi sono
                  </Link>
                </li>
                <li>
                  <Link
                    to="/personaltraining"
                    onClick={() => setMobileNavOpen(false)}
                    className="flex text-gray-300 hover:text-gray-200 py-2"
                  >
                    Personal
                  </Link>
                </li>
                <li>
                  <Link
                    to="/homegym"
                    onClick={() => setMobileNavOpen(false)}
                    className="flex text-gray-300 hover:text-gray-200 py-2"
                  >
                    Home Gym
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    onClick={() => setMobileNavOpen(false)}
                    className="flex text-gray-300 hover:text-gray-200 py-2"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    onClick={() => setMobileNavOpen(false)}
                    className="flex text-gray-300 hover:text-gray-200 py-2 mb-5"
                  >
                    Contatti
                  </Link>
                </li>
                {/* <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Support</span>
                  <ul className="pl-4">
                    <li>
                      <Link
                        to="/contact"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/help"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        Help center
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/404"
                        className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2"
                      >
                        404
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {process.env.NODE_ENV === "development" && (
                  <>
                    <li>
                      <Link
                        to="/signin"
                        className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center border-t border-gray-700 pt-5"
                      >
                        Sign in
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/signup"
                        className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
                      >
                        Sign up
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
