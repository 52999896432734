import React from "react";
import { Link } from "react-router-dom";

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative pt-20 md:pt-28">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <img
              src={require("../images/icons/homelogo.png")}
              className="mb-16 md:mb-10"
              data-aos="fade-up"
              data-aos-delay="100"
            ></img>
            {/* <h2 className="text-10xl mb-5" data-aos="fade-up">
              RS TRAINING
            </h2> */}
            <p
              className="text-2xl md:text-4xl font-dancing text-gray-400 italic md:mb-12 mb-32"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Allena la mente, il corpo la seguirà.
            </p>
            {/* <h3
              className="btn-sm text-white bg-purple-600 h3 mt-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              RS TRAINING
            </h3> */}
            <p
              className="text-xl text-gray-400 mt-10 md:mx-10 mx-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Qualunque sia il tuo obiettivo: perfomance fisica, dimagrimento,
              forza o focus su glutei e addominali, ti fornirò protocolli
              efficaci su misura per te e per i tuoi obiettivi.
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="200"
              ></div>

              <div
                className="w-16 h-16 mb-4 bg-purple-600 rounded-full content-center flex flex-wrap"
                width="64"
                height="64"
                rx="32"
              >
                <img
                  src={require("../images/icons/pt.png")}
                  className="fill-current text-white-300 h-8 w-10 ml-2 ml-3 white"
                  alt="pt-img"
                  color={"#fff"}
                />
              </div>
              <Link to="/personaltraining" className="h4 mb-2">
                PERSONAL TRAINING
              </Link>
              <p className="text-lg text-gray-400 text-center">
                Il mio obiettivo è quello di trasmetterti la passione per il
                fitness. Il tuo sarà quello di seguirmi senza più scuse!
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="400"
              ></div>
              <div
                className="w-16 h-16 mb-4 bg-purple-600 rounded-full content-center flex flex-wrap"
                width="64"
                height="64"
                rx="32"
              >
                <img
                  src={require("../images/icons/homegym.png")}
                  className="fill-current text-white-300 h-10 w-10 ml-2 ml-3 mb-1 white"
                  alt="pt-img"
                  color={"#fff"}
                />
              </div>
              <Link to="/homegym" className="h4 mb-2">
                HOME GYM
              </Link>
              <p className="text-lg text-gray-400 text-center">
                Vuoi allenarti a casa seguendo una programmazione ben
                strutturata? Scegli il pacchetto che fa per te! Riceverai
                supporto h24 e consigli pratici.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div
                className="w-16 h-16 mb-4 bg-purple-600 rounded-full content-center flex flex-wrap"
                width="64"
                height="64"
                rx="32"
              >
                <img
                  src={require("../images/icons/rsblog.png")}
                  className="fill-current text-white-300 h-10 w-10 ml-2 ml-3 white"
                  alt="pt-img"
                  color={"#fff"}
                />
              </div>
              <Link to="/blog" className="h4 mb-2">
                RS BLOG
              </Link>
              <p className="text-lg text-gray-400 text-center">
                Dalla teoria alla pratica. Evidenze scientifiche per sfatare
                qualche falso mito.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
