import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import PricingTables from "../partials/PricingTables";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import HeroAbout from "../partials/HeroAbout";

const Personal = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      <main className="flex-grow">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <HeroAbout
          noMargin={true}
          pageTitle={"Personal Training"}
          pageSubtitle={
            "Essere seguiti durante l’allenamento è sicuramente il metodo più efficace per spingerti a dare il massimo! Le mie lezioni hanno la durata di un’ora e si svolgono nel mio centro Fitness. Di seguito potrai scegliere il pacchetto che fa per te."
          }
        />

        {/* photos */}

        {/* prices */}
        <PricingTables noMargin={true} />

        <Cta />
      </main>

      <Footer />
    </div>
  );
};

export default Personal;
