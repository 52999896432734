import React, { useState, useEffect } from "react";
import { PricingTable } from "../components/pricing/PricingTable";
import { getDocsWithCondition } from "../utils/Firebase";
import ClipLoader from "react-spinners/ClipLoader";

const PricingTables = ({
  noMargin = true,
  title = "Prezzi",
  copy = "",
  toggleAvailable = false,
  personal = true,
}) => {
  const [value, setValue] = useState(false);
  const [prices, setPrices] = useState([]);
  const [gridclass, setGrid] = useState("lg:grid-cols-4");
  const [loading, setLoading] = useState(true);

  const sortPrices = (prices) => {
    return prices.sort((a, b) => a.personal - b.personal);
  };

  const callback = (prices) => {
    setPrices(sortPrices(prices));
    if (prices.length) setLoading(false);
  };

  useEffect(() => {
    if (personal) {
      setLoading(true);
      getDocsWithCondition("prices", "personal", callback);
      setGrid("lg:grid-cols-2");
    } else {
      if (!value) {
        setLoading(true);
        getDocsWithCondition("prices", "manubri", callback);
        setGrid("lg:grid-cols-4");
      } else {
        setLoading(true);
        getDocsWithCondition("prices", "corpo", callback);
        setGrid("lg:grid-cols-3");
      }
    }
  }, [value, personal]);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div
          className={`${!noMargin ? "pt-32" : "pt-0"} pb-12 ${
            !noMargin ? "md:pt-32" : "md:pt-20"
          } md:pb-20`}
        >
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="h2 mb-4" data-aos="fade-up" data-aos-delay="100">
              {title}
            </h1>
            <p
              className="text-xl text-gray-400"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {copy}
            </p>
          </div>

          <div>
            {/* Pricing toggle */}
            {toggleAvailable && (
              <div
                className="flex justify-center mb-16"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="inline-flex items-center">
                  <div className="text-gray-500 font-medium mr-3">
                    Manubri / Bilanciere
                  </div>
                  <div className="form-switch focus-within:shadow-outline">
                    <input
                      type="checkbox"
                      name="pricing-toggle"
                      id="pricing-toggle"
                      className="sr-only"
                      checked={value}
                      onChange={() => setValue(!value)}
                    />
                    <label className="bg-gray-600" htmlFor="pricing-toggle">
                      <span className="bg-gray-200" aria-hidden="true"></span>
                    </label>
                  </div>
                  <div className="text-gray-500 font-medium ml-3">
                    Corpo Libero
                  </div>
                </div>
              </div>
            )}

            {loading ? (
              <div className="flex justify-center items-center h-full py-56">
                <ClipLoader color={"#ffffff"} loading={true} size={100} />
              </div>
            ) : (
              <div
                className={`max-w-sm mx-auto grid gap-8 ${gridclass} lg:gap-6 items-start lg:max-w-none`}
              >
                {prices.map((item, index) => {
                  return (
                    <PricingTable
                      key={index}
                      index={index}
                      service={item.service}
                      mostPopular={item.mostPopular}
                      prices={item.prices}
                      description={item.description}
                      services={item.services}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingTables;
