import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import PricingTables from "../partials/PricingTables";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import HeroAbout from "../partials/HeroAbout";

const HomeGym = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      <main className="flex-grow">
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <HeroAbout
          pageTitle={"Home Gym"}
          pageSubtitle={
            "In base alla tua attrezzatura potrai trovare il piano di allenamento che fa per te! \
            Contattami e descrivimi quali sono i tuoi obiettivi. Dopo una rapida anamnesi inizieremo la programmazione del tuo allenamento."
          }
          home={true}
        />
        {/* prices */}
        <PricingTables
          title={"Prezzi"}
          toggleAvailable={true}
          personal={false}
          noMargin={true}
        />
        {/* help */}
        {/* <Cta /> */}
      </main>

      <Footer />
    </div>
  );
};

export default HomeGym;
