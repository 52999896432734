import React, { useState, useEffect } from "react";
import { getDocsAsArray } from "../utils/Firebase";
import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import BlogList from "../partials/BlogList";
import Newsletter from "../partials/Newsletter";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../partials/Footer";

function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const callback = (articles) => {
    setPosts(articles);
  };

  useEffect(() => {
    if (!posts.length) {
      getDocsAsArray("articles", callback);
    } else {
      setLoading(false);
    }
  });

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <ClipLoader color={"#ffffff"} loading={true} size={100} />
          </div>
        ) : (
          <>
            <BlogList posts={posts} />
            {/* <Newsletter /> */}
          </>
        )}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Blog;
