import React from "react";

export const TimelineItem = ({
  title,
  subtitle,
  year = null,
  copy,
  hidden = true,
  last,
  delay,
}) => {
  return (
    <div
      className="relative py-4 md:py-6 pl-24"
      data-aos="fade-up"
      data-aos-delay={delay}
      data-aos-anchor="[data-aos-id-timeline]"
    >
      <div className="pl-2">
        {!hidden && (
          <div className="font-architects-daughter text-xl text-purple-600 mb-2">
            {title}
          </div>
        )}
        <div className="flex items-center mb-3">
          {year && (
            <div className="absolute left-0 mr-10 inline-flex text-sm font-semibold py-1 px-3 inline-block text-green-600 bg-green-200 rounded-full">
              {year}
            </div>
          )}

          {!last && (
            <div
              className="absolute left-0 mt-3 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3"
              aria-hidden="true"
            ></div>
          )}
          <div
            className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
            aria-hidden="true"
          ></div>
          <h4 className="h4">{subtitle}</h4>
        </div>
        {!hidden && <p className="text-lg text-gray-400">{copy}</p>}
      </div>
    </div>
  );
};
