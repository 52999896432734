import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
// import CtaContact from "../partials/CtaContact";
import Footer from "../partials/Footer";
import Map from "../components/maps/Map";

function Contact() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-8 md:pt-40 md:pb-8">
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4" data-aos="fade-up">
                  CONTATTI
                </h1>
                <div
                  className="text-xl text-gray-400"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Telefono:{" "}
                  <a
                    className="inline hover:underline text-white font-bold"
                    href="https://wa.me/393517181835/?text=Ciao Riccardo, ti contatto per informazioni su Personal Training."
                  >
                    +39 3517181835
                  </a>
                </div>
                <div
                  className="text-xl text-gray-400"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  E-mail:{" "}
                  <a
                    className="inline text-white font-bold hover:underline"
                    href="mailto:rstraining.it@gmail.com"
                  >
                    rstraining.it@gmail.com
                  </a>
                </div>
                <div
                  className="text-xl text-gray-400"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  P.IVA:{" "}
                  <p className="inline text-white font-bold">11750440965</p>
                </div>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h2 mb-4" data-aos="fade-up">
                  SOCIAL
                </h1>
                <div
                  className="text-xl text-gray-400"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Seguimi su Instagram:{" "}
                  <p className="inline text-white font-bold">
                    riccardoscroccaro
                  </p>
                  <a
                    // target="blank"
                    href="https://www.instagram.com/rs_training__"
                    className="text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out absolute ml-2"
                    aria-label="Instagram"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="20.145" cy="11.892" r="1" />
                      <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                      <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="max-w-3xl mx-auto text-center md:pb-5">
                <h1 className="h2 mb-4" data-aos="fade-up">
                  INDIRIZZI
                </h1>
                {/* <a
                  className="text-xl text-gray-400 hover:underline"
                  data-aos-delay="200"
                  href="https://www.google.com/maps/dir//Via+Santa+Liberata,+9,+20832+Desio+MB/@45.6090792,9.2040061,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786bc200be5b17d:0x584cc0a0b4ff9608!2m2!1d9.2061948!2d45.6090792"
                >
                  {"Via Santa Liberata 9, 20832 Desio (MB)\n"}
                </a> */}
              </div>

              <div className="max-w-3xl mx-auto text-center md:pb-12">
                <h1 className="h4 mb-4" data-aos="fade-up">
                  SEDE DI DESIO
                </h1>

                <a
                  className="text-xl text-gray-400 hover:underline"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  href="https://www.google.com/maps/dir//Via+Santa+Liberata,+9,+20832+Desio+MB/@45.6090792,9.2040061,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786bc200be5b17d:0x584cc0a0b4ff9608!2m2!1d9.2061948!2d45.6090792"
                >
                  {"Via Santa Liberata 9, 20832 Desio (MB)\n"}
                </a>
              </div>

              <div className="max-w-3xl mx-auto text-center md:pb-12">
                <h1 className="h4 mb-4" data-aos="fade-up">
                  SEDE DI GIUSSANO
                </h1>

                <a
                  className="text-xl text-gray-400 hover:underline"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  href="https://www.google.com/maps/dir//Via+4+Novembre,+49,+Giussano,+MB/@45.6720222,9.1989153,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4786bd2743a60159:0x42dcff66c0bbbce!2m2!1d9.2032927!2d45.6720148"
                >
                  {`Via 4 Novembre 49, Giussano (MB) - Studio BST`}
                </a>
              </div>

              {/* Contact form */}
              {/* <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">First Name <span className="text-red-600">*</span></label>
                    <input id="first-name" type="text" className="form-input w-full text-gray-300 border-red-500 focus:border-red-500" placeholder="Enter your first name" required />
                    <p className="text-red-500 text-sm mt-2">This field is required</p>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Last Name <span className="text-red-600">*</span></label>
                    <input id="last-name" type="text" className="form-input w-full text-gray-300" placeholder="Enter your last name" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                    <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="Enter your email address" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">Subject <span className="text-red-600">*</span></label>
                    <input id="subject" type="text" className="form-input w-full text-gray-300" placeholder="How can we help you?" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="country">Country</label>
                    <select id="country" className="form-select w-full text-gray-300">
                      <option>United States</option>
                      <option>United Kingdom</option>
                      <option>Germany</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message</label>
                    <textarea id="message" rows="4" className="form-textarea w-full text-gray-300" placeholder="Write your message"></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-gray-400 ml-2">I agree to the privacy policy</span>
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Send</button>
                  </div>
                </div>
              </form> */}
            </div>
          </div>
          {/* <div className="h-screen -mb-40">
            <Map />
          </div> */}
        </section>

        {/*  Bottom CTA */}
        {/* <CtaContact /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Contact;
