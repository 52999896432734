import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Transition from "../utils/Transition.js";

function Tabs() {
  const [tab, setTab] = useState(1);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height =
        tabs.current.children[tab].offsetHeight + "px";
    }
  };

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setTab((active) => (active + 1) % 4);
    }, autorotateTiming);
    return () => clearInterval(interval);
  }, [autorotate]);

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12" data-aos-id-tabs>
            <h2
              className="h2 mb-4"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-tabs]"
            >
              Allenamento e Salute come Stile di Vita
            </h2>
          </div>

          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div
              className="flex flex-wrap justify-center -m-2"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-tabs]"
            >
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 0 && "opacity-50"
                  }`}
                onClick={() => {
                  setTab(0);
                  setAutorotate(null);
                }}
              >
                {/* <svg
                  className="w-4 h-4 fill-current text-purple-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
                </svg> */}
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
                  ALLENAMENTO
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 1 && "opacity-50"
                  }`}
                onClick={() => {
                  setTab(1);
                  setAutorotate(null);
                }}
              >
                {/* <svg
                  className="w-4 h-4 fill-current text-purple-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.043 6.293S9.79 1.905 6.745 0A5.37 5.37 0 014.72 3.887C3.42 5.03.974 7.6 1 10.34A6.285 6.285 0 004.451 16a3.984 3.984 0 011.394-2.755 3.253 3.253 0 001.246-2.185 5.856 5.856 0 013.1 4.881v.013a5.883 5.883 0 003.428-5.106c.216-2.574-1.194-6.074-2.445-7.218a6.793 6.793 0 01-2.13 2.663z" />
                </svg> */}
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
                  BENESSERE
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 2 && "opacity-50"
                  }`}
                onClick={() => {
                  setTab(2);
                  setAutorotate(null);
                }}
              >
                {/* <svg
                  className="w-4 h-4 fill-current text-purple-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2 0-.2 0-.3.1-.5L3.6 5 5 3.6l2.5 2.5c.2-.1.3-.1.5-.1 1.1 0 2 .9 2 2s-.9 2-2 2z" />
                </svg> */}
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
                  POSTURA
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${tab !== 3 && "opacity-50"
                  }`}
                onClick={() => {
                  setTab(3);
                  setAutorotate(null);
                }}
              >
                {/* <svg
                  className="w-4 h-4 fill-current text-purple-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z" />
                </svg> */}
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
                  PALESTRA
                </span>
              </button>
              {/* <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-gray-800 rounded-full group transition duration-500 ${
                  tab !== 5 && "opacity-50"
                }`}
                onClick={() => setTab(5)}
              >
                <svg
                  className="w-4 h-4 fill-current text-purple-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z" />
                </svg>
                <span className="text-gray-400 group-hover:text-gray-200 transition-colors duration-150 ease-in-out">
                  Quick action
                </span>
              </button> */}
            </div>

            {/* Tabs items */}
            <div
              className="relative flex flex-col mt-16"
              data-aos="fade-up"
              ref={tabs}
            >
              {/* Item 1 */}
              <Transition
                show={tab === 0}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                    <img
                      className="w-full h-full object-cover"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fquarta.jpeg?alt=media&token=2ea8450f-de2d-4fa8-b8ab-534a0160a894"
                      }
                      width="516"
                      height="387"
                      alt="Tabs 01"
                    />
                  </figure>
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2">ALLENAMENTO</h4>
                    <p className="text-lg text-gray-400">
                      {
                        "Allenamenti con sovraccarichi (Resistance Training), Rieducazione Posturale, \
                        Strength&Conditioning (S&C), Circuit e Metabolic Training. A partire dal tuo obiettivo, \
                        costruire un fisico migliore, aumentare forza e potenza muscolare, o semplicemente tonificare \
                        i glutei, ti fornirò le linee guida più efficaci e su misura per TE."
                      }
                    </p>
                    <Link
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                      to="/personaltraining"
                    >
                      <span className="text-sm">SCOPRI DI PIU</span>
                      <svg
                        className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 2 */}
              <Transition
                show={tab === 1}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <img
                      className="w-full h-full object-cover"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fbenessere.jpeg?alt=media&token=bd8b51d5-2063-41c6-ba4c-dc7fb534e42f"
                      }
                      width="516"
                      height="387"
                      alt="Tabs 02"
                    />
                  </figure>
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2">BENESSERE</h4>
                    <p className="text-lg text-gray-400">
                      Essendo specializzato in Attività Fisica per il Benessere,
                      il mio obiettivo non sarà solo quello di strutturare un
                      piano d’allenamento, ma sarà anche quello di farti
                      comprendere l’importanza dei corretti stili di vita per un
                      Benessere Psicofisico a 360 gradi! Postura, alimentazione,
                      sonno, stress.. sono solo alcuni dei possibili fattori che
                      possono essere responsabili di un malessere fisico e
                      mentale. Migliorarli aiuterà te e anche la tua perfomance
                      fisica.
                    </p>
                    <Link
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                      to="/personaltraining"
                    >
                      <span className="text-sm">SCOPRI DI PIU</span>
                      <svg
                        className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 3 */}
              <Transition
                show={tab === 2}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                    <img
                      className="w-full h-full object-cover"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Flombalgia.jpg?alt=media&token=a6b25475-a408-46b1-9ef7-04b7d75c452e"
                      }
                      width="516"
                      height="387"
                      alt="Tabs 01"
                    />
                  </figure>
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2">POSTURA</h4>
                    <p className="text-lg text-gray-400">
                      Fondamentale per me sarà anche farti comprendere
                      l’importanza di una corretta postura da mantenere sia
                      durante le attività quotidiane e durante l’esecuzione di
                      tutti gli esercizi proposti in palestra. Non sarà così
                      raro dimostrarti come una corretta postura, accompagnata
                      da un livello sufficiente di attività fisica, possa
                      tutelarti o addirittura attenuare eventuali dolori
                      lombari, cervicali ecc..
                    </p>
                    <Link
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                      to="/personaltraining"
                    >
                      <span className="text-sm">SCOPRI DI PIU</span>
                      <svg
                        className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 4 */}
              <Transition
                show={tab === 3}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <img
                      className="w-full h-full object-cover"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fpalestra.jpeg?alt=media&token=a9d51d59-47b4-4dff-8e9f-df9b0f9b7a3a"
                      }
                      width="516"
                      height="387"
                      alt="Tabs 02"
                    />
                  </figure>
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2">PALESTRA</h4>
                    <p className="text-lg text-gray-400">
                      Nel mio centro Fitness propongo per la maggior parte del
                      tempo esercizi con bilanciere, manubri o kettlebell. Ciò
                      non significa che in particolari situazioni non si possa
                      integrare all’allenamento l'utilizzo di macchinari, spesso
                      più sicuri e con maggior capacità di reclutare i muscoli
                      target (soprattutto in neofiti).
                    </p>
                    <Link
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                      to="/personaltraining"
                    >
                      <span className="text-sm">SCOPRI DI PIU</span>
                      <svg
                        className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </Link>
                  </div>
                </article>
              </Transition>

              {/* Item 5 */}
              {/* <Transition
                show={tab === 5}
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterStart="opacity-0 scale-98"
                enterEnd="opacity-100 scale-100"
                leave="transition ease-out duration-300 transform absolute"
                leaveStart="opacity-100 scale-100"
                leaveEnd="opacity-0 scale-98"
              >
                <article className="relative max-w-md mx-auto md:max-w-none">
                  <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                    <img
                      className="w-full h-full object-cover"
                      src={require("../images/tabs-image-01.jpg")}
                      width="516"
                      height="387"
                      alt="Tabs 01"
                    />
                  </figure>
                  <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2">
                      Optimize and scale, easy to start
                    </h4>
                    <p className="text-lg text-gray-400">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <a
                      className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </a>
                  </div>
                </article>
              </Transition> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
