import React from "react";
import { Link } from "react-router-dom";

export const PricingTable = ({
  mostPopular,
  service,
  prices,
  description,
  services,
  index,
}) => {
  return (
    <div
      className="relative flex flex-col h-full p-6 bg-gray-800"
      data-aos="fade-up"
      data-aos-delay={index * 200}
      key={index}
    >
      {mostPopular && (
        <div className="absolute top-0 right-0 mr-6 -mt-4">
          <div className="inline-flex text-sm font-semibold py-1 px-3 mt-px text-green-600 bg-green-200 rounded-full">
            Più venduto
          </div>
        </div>
      )}
      <div className="mb-4 pb-4 border-b border-gray-700">
        <div className="h4 text-purple-600 mb-1">{service}</div>
        <div className="inline-flex items-baseline mb-2">
          <span className="text-2xl md:text-3xl font-medium mb-3 text-gray-400">
            {prices[0]}
          </span>
          <span className="h2">{prices[1]}</span>
          <span className="font-medium text-gray-400"> {`${prices[2]}`}</span>
        </div>
        <div className="text-gray-400">{description}</div>
      </div>
      <div className="font-medium mb-3">Servizi inclusi:</div>
      <ul className="text-gray-400 -mb-3 flex-grow">
        {services.map((item, index) => {
          return (
            <li className="flex items-center mb-3" key={index}>
              <svg
                className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
              </svg>
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
      <div className="border border-gray-700 p-3 mt-6">
        <Link
          className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full"
          to="/contact"
        >
          CONTATTAMI
        </Link>
      </div>
    </div>
  );
};
