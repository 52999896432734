import React, { useState } from "react";
import { Pagination } from "../components/blog/Pagination";
import { Post } from "../components/blog/Post";
import { FeaturedArticle } from "../components/blog/FeaturedArticle";

const BlogList = ({ posts }) => {
  const [currentPage, setPage] = useState(1);
  const [postsPerPage] = useState(6);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const highlighted = posts.filter((post) => post.highlighted)[0];

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/*  Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1" data-aos="fade-up">
              RS BLOG
            </h1>
            <p
              className="text-2xl text-white-400 mb-8 mt-10"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              In questa sezione puoi trovare articoli che possono esserti utili.
            </p>
          </div>

          {/*  Featured article */}
          <div className="pb-12 md:pb-20">
            <FeaturedArticle post={highlighted} />
          </div>

          {/*  Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none" id="latestArticles">
            {/*  Section title */}
            <h4
              className="h4 pb-6 mb-10 border-b border-gray-700"
              data-aos="fade-up"
            >
              Articoli più recenti
            </h4>

            <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">
              {currentPosts.map((post, index) => {
                return <Post post={post} index={index} key={index}/>;
              })}
            </div>
          </div>

          <Pagination
            setPage={setPage}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            posts={posts.length}
          />
        </div>
      </div>
    </section>
  );
};

export default BlogList;
