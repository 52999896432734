import React from "react";
import { TimelineItem } from "../components/about/TimelineItem";
import { TimelineData } from "../data/about/TimelineData";

function Timeline() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">La mia formazione</h2>
            {/* <p className="text-xl text-gray-400">
              La formazione accademica è uno dei valori fondamentali che
              intendo sfruttare per rendere la vostra esperienza migliore!
            </p> */}
          </div>

          <div
            className="max-w-3xl mx-auto -my-4 md:-my-6"
            data-aos-id-timeline
          >
            {TimelineData.map((item, index) => {
              return (
                <TimelineItem
                  key={index}
                  title={item.title}
                  copy={item.copy}
                  subtitle={item.subtitle}
                  year={item.year || null}
                  last={item.last || false}
                  delay={index * 200}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;
