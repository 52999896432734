// This import loads the firebase namespace along with all its type information.
import firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyC_ljGuAnrXXhrow3BofrCGQR-YBsZFlOs",
  authDomain: "rs-training-react.firebaseapp.com",
  projectId: "rs-training-react",
  storageBucket: "rs-training-react.appspot.com",
  messagingSenderId: "828851826538",
  appId: "1:828851826538:web:a5feb29ea7bf6412f77f13",
};

// firebase init method
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const getDoc = (collection, id, callback) => {
  db.collection(collection)
    .doc(id)
    .get()
    .then((doc) => callback(!doc.exists ? false : doc.data()));
};

export const getDocsAsArray = (collection, callback) => {
  db.collection(collection)
    .orderBy("date", "desc")
    .get()
    .then((snapshot) =>
      callback(
        snapshot.docs.map((doc) =>
          Object.assign(doc.data(), { postId: doc.id })
        )
      )
    );
};

export const getDocsWithCondition = (collection, condition, callback) => {
  db.collection(collection)
    .where(condition, ">", 0)
    .get()
    .then((snapshot) =>
      callback(
        snapshot.docs.map((doc) =>
          Object.assign(doc.data(), { postId: doc.id })
        )
      )
    );
};

export const getDocsWithConditionAndLimit = (
  collection,
  condition,
  callback,
  limit,
  array
) => {
  db.collection(collection)
    .where(condition, "array-contains-any", array)
    .limit(limit)
    .get()
    .then((snapshot) =>
      callback(
        snapshot.docs.map((doc) =>
          Object.assign(doc.data(), { postId: doc.id })
        )
      )
    );
};
