import React from "react";
import { Link } from "react-router-dom";
import { tagColors } from "../../components/blogPost/Colors";
import { getFullDate } from "../../components/blogPost/DateFormatting";

export const Post = ({ post, index }) => {
  const date = post.date.toDate();

  const getISODate = () => {
    return getFullDate(date.getDate(), date.getMonth(), date.getFullYear());
  };

  return (
    <article
      className="flex flex-col h-full"
      data-aos="fade-up"
      data-aos-delay={index * 200}
    >
      <header>
        <Link to={`/blog-post/${post.postId}`} className="block mb-6">
          <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
            <img
              className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
              src={post.displayPhoto}
              width="352"
              height="198"
              alt="News 01"
            />
          </figure>
        </Link>
        <div className="mb-3">
          <ul className="flex flex-wrap text-xs font-medium -m-1">
            {post.tags.map((tag, index) => {
              return (
                <li className="m-1" key={index}>
                  <div
                    className={`inline-flex text-center text-gray-100 py-1 px-3 rounded-full ${tagColors[tag]} transition duration-150 ease-in-out`}
                  >
                    {tag}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <h3 className="h4 mb-2">
          <Link
            to={`/blog-post/${post.postId}`}
            className="hover:text-gray-100 transition duration-150 ease-in-out"
          >
            {post.title}
          </Link>
        </h3>
      </header>
      <p className="text-lg text-gray-400 flex-grow">
        {(post.description || post.secondaryDescription[0]).substr(0, 120) +
          "..."}
      </p>
      <footer className="flex items-center mt-4">
        <div>
          <img
            className="rounded-full flex-shrink-0 mr-4"
            src={post.writerPhoto || require("../../images/news-author-01.jpg")}
            width="40"
            height="40"
            alt={`author ${post.postId}`}
          />
        </div>
        <div className="font-medium">
          <div className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out">
            {post.writer}
          </div>
          <span className="text-gray-500">{getISODate()}</span>
        </div>
      </footer>
    </article>
  );
};
