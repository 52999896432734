import React, { useState, useEffect } from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import BlogSingle from "../partials/BlogSingle";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import { getDoc } from "../utils/Firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import { DocNotFound } from "../components/blogPost/DocNotFound";

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [docExists, setExists] = useState(true);

  // getting article id from url path
  const { id } = useParams();

  const callback = (article) => {
    if (!article) {
      setExists(false);
      setIsLoading(false);
      return;
    }
    setPost(article);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getDoc("articles", id, callback);
  }, [id]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      {/* <Header /> */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <ClipLoader color={"#ffffff"} loading={true} size={100} />
          </div>
        ) : (
          <>
            {docExists ? (
              <>
                <BlogSingle post={post} />
                {/* <Newsletter /> */}
              </>
            ) : (
              <DocNotFound />
            )}
          </>
        )}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default BlogPost;
