import React from "react";

export const Paragraph = ({ paragraph, index }) => {
  return (
    <div key={index} id={paragraph.title}>
      <p
        className={`${paragraph.indent ? "h4" : "h3"} mb-4 text-gray-200`}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {paragraph.indent ? "· " + paragraph.title : paragraph.title}
      </p>
      {paragraph.subParagraphs.map((item, index) => {
        if (paragraph.biblio) {
          return (
            <li
              className="mb-8 ml-6"
              key={index}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {item}
            </li>
          );
        }
        return (
          <p
            className="mb-8"
            key={index}
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {item}
          </p>
        );
      })}
      {paragraph.image && (
        <figure className="mb-8" data-aos="fade-up" data-aos-delay="100">
          <img
            className="w-full"
            src={paragraph.image.filename}
            width="768"
            height="432"
            alt="News inner"
          />
          <figcaption className="text-sm text-center text-gray-500 mt-3">
            {paragraph.image.description}
          </figcaption>
        </figure>
      )}
    </div>
  );
};
