import React from "react";
import { Link as ScrollLink } from "react-scroll";

export const Pagination = ({ setPage, currentPage, postsPerPage, posts }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(posts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (pageNumbers.length <= 1) return null;

  return (
    <nav
      className="flex justify-center pt-16"
      role="navigation"
      aria-label="Pagination Navigation"
    >
      <ul className="inline-flex flex-wrap font-medium text-sm -m-1">
        <li className="m-1">
          <ScrollLink spy={true} to={"latestArticles"} smooth={true}>
            <div
              onClick={() =>
                setPage((item) => {
                  if (item === 1) {
                    return item;
                  }
                  return item - 1;
                })
              }
              className={`inline-flex h-10 min-w-10 justify-center items-center cursor-pointer bg-gray-800 px-4 rounded-full ${
                currentPage === 1
                  ? "text-gray-500"
                  : "text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out"
              }`}
            >
              Precedente
            </div>
          </ScrollLink>
        </li>
        {pageNumbers.map((item, index) => {
          return (
            <li className="m-1" key={index}>
              <ScrollLink spy={true} to={"latestArticles"} smooth={true}>
                <div
                  className={`inline-flex h-10 min-w-10 cursor-pointer justify-center items-center ${
                    item === currentPage ? "bg-purple-600" : "bg-gray-800"
                  } px-2 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out`}
                  onClick={() => setPage(item)}
                >
                  {item}
                </div>
              </ScrollLink>
            </li>
          );
        })}
        <li className="m-1">
          <ScrollLink spy={true} to={"latestArticles"} smooth={true}>
            <div
              onClick={() =>
                setPage((item) => {
                  if (item === pageNumbers.length) {
                    return item;
                  }
                  return item + 1;
                })
              }
              className={`inline-flex h-10 min-w-10 justify-center items-center cursor-pointer bg-gray-800 px-4 rounded-full ${
                currentPage === pageNumbers.length
                  ? "text-gray-500"
                  : "text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out"
              }`}
            >
              Successiva
            </div>
          </ScrollLink>
        </li>
      </ul>
    </nav>
  );
};
