import React from "react";

const HeroAbout = ({
  home = false,
  image = "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fquarta.jpeg?alt=media&token=2ea8450f-de2d-4fa8-b8ab-534a0160a894",
  pageTitle = "CHI SONO",
  pageSubtitle = "Benvenuto nel mio sito web! Mi chiamo Riccardo Scroccaro e da sempre sono appassionato di sport e fitness. Ho iniziato come giocatore di basket, ora invece mi dedico esclusivamente al fitness in palestra. Ho terminato la laurea Magistrale in Attività Fisica per il Benessere presso l’Università degli Studi di Milano e ho iniziato il percorso di Osteopatia per offrire un servizio olistico di benessere, postura, biomeccanica e perfomance.",
}) => {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={
            home
              ? "https://firebasestorage.googleapis.com/v0/b/rs-training-react.appspot.com/o/main-photos%2Fhomegym.jpg?alt=media&token=dbd09d2c-f0c8-478b-a2e0-a98fca11f558"
              : image
          }
          width="1440"
          height="394"
          alt="About"
        />
        <div
          className="absolute inset-0 bg-gray-900 opacity-75"
          aria-hidden="true"
        ></div>
      </div>

      {/* Hero content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 mb-6" data-aos="fade-up">
              {pageTitle}
            </h1>
            <p
              className="text-2xl text-white-400 mb-8"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {pageSubtitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroAbout;
