import React, { useState, useEffect, useRef } from "react";
import { Review } from "../components/reviews/Review";
import ClipLoader from "react-spinners/ClipLoader";
import { getDocsAsArray } from "../utils/Firebase";
import Transition from "../utils/Transition.js";

function Testimonials() {
  const [reviews, setReviews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);

  const stopAutorotate = () => {
    setAutorotate(null);
  };

  const heightFix = () => {
    if (!isLoading && testimonials.current.children[active]) {
      testimonials.current.style.height =
        testimonials.current.children[active].offsetHeight + "px";
    }
  };

  const testimonials = useRef(null);

  useEffect(() => {
    heightFix();
  }, [active]);

  const separateReviews = (reviews) => {
    let revs = [];
    let three = [];

    for (let i = 0; i < reviews.length; i++) {
      three.push(reviews[i]);

      if (three.length == 3) {
        revs.push(three);
        three = [];
      }

      if (i == reviews.length - 1 && three.length > 0) {
        revs.push(three);
      }
    }
    return revs;
  };

  const callback = (reviews) => {
    setReviews(separateReviews(reviews));
  };

  useEffect(() => {
    if (!autorotate || !reviews) return;
    const interval = setInterval(() => {
      setActive((active) => (active + 1) % reviews.length);
    }, autorotateTiming);
    return () => clearInterval(interval);
  }, [active, autorotate, reviews]);

  useEffect(() => {
    if (!reviews) {
      getDocsAsArray("reviews", callback);
    } else {
      setIsLoading(false);
    }
  });

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Recensioni</h2>
            <p className="text-xl text-gray-400">
              Per me il rapporto col cliente è di fondamentale importanza!
            </p>
          </div>

          {/* Testimonials */}
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <ClipLoader color={"#ffffff"} loading={true} size={80} />
            </div>
          ) : (
            <div ref={testimonials}>
              {reviews.map((item, index) => {
                return (
                  <Transition
                    key={index}
                    show={active === index}
                    appear={true}
                    className="max-w-sm mx-auto grid gap-8 lg:grid-cols-3 lg:gap-6 items-start lg:max-w-none"
                    enter="transition ease-in-out duration-500 transform order-first"
                    enterStart="opacity-0 scale-98"
                    enterEnd="opacity-100 scale-100"
                    leave="transition ease-out duration-300 transform absolute"
                    leaveStart="opacity-100 scale-100"
                    leaveEnd="opacity-0 scale-98"
                  >
                    {item.map((review, index) => {
                      return (
                        <Review
                          key={index}
                          text={review.text}
                          image={review.image}
                          reviewer={review.reviewer}
                        />
                      );
                    })}
                  </Transition>
                );
              })}
              <div className="flex justify-center mt-6">
                {reviews.map((item, index) => (
                  <button
                    className="p-1 group"
                    key={index}
                    onClick={() => {
                      setActive(index);
                      stopAutorotate();
                    }}
                  >
                    <span
                      className={`block w-2 h-2 rounded-full group-hover:bg-gray-400 transition duration-150 ease-in-out ${
                        active === index ? "bg-gray-200" : "bg-gray-500"
                      }`}
                    ></span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
