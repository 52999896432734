import React from "react";
import { Link } from "react-router-dom";
import { getFullDate } from "../../components/blogPost/DateFormatting";

export const FeaturedArticle = ({ post }) => {
  const date = post.date.toDate();

  const getISODate = () => {
    return getFullDate(date.getDate(), date.getMonth(), date.getFullYear());
  };

  return (
    <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
      <Link
        to={`/blog-post/${post.postId}`}
        className="relative block group"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <div
          className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-1 md:translate-x-2 xl:translate-y-2 xl:translate-x-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
          aria-hidden="true"
        ></div>
        <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
          <img
            className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
            src={post.displayPhoto}
            width="540"
            height="303"
            alt="Blog post 01"
          />
        </figure>
      </Link>
      <div data-aos="fade-left" data-aos-delay="200">
        <header>
          <div className="mb-3">
            <ul className="flex flex-wrap text-xs font-medium -m-1">
              <li className="m-1">
                <div className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">
                  In evidenza
                </div>
              </li>
            </ul>
          </div>
          <h3 className="h3 text-2xl lg:text-3xl mb-2">
            <Link
              to={`/blog-post/${post.postId}`}
              className="hover:text-gray-100 transition duration-150 ease-in-out"
            >
              {post.title}
            </Link>
          </h3>
        </header>
        <p className="text-lg text-gray-400 flex-grow">{post.description}</p>
        <footer className="flex items-center mt-4">
          <div to="#">
            <img
              className="rounded-full flex-shrink-0 mr-4"
              src={post.writerPhoto || require("../../images/news-author-04.jpg")}
              width="40"
              height="40"
              alt="Author 04"
            />
          </div>
          <div>
            <div
              to="#"
              className="font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
            >
              {post.writer}
            </div>
            <span className="text-gray-500">{getISODate()}</span>
          </div>
        </footer>
      </div>
    </article>
  );
};
