import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Paragraph } from "../components/blogPost/Paragraph";
import { tagColors } from "../components/blogPost/Colors";
import { Link as ScrollLink } from "react-scroll";
import { getFullDate } from "../components/blogPost/DateFormatting";
import { getDocsWithConditionAndLimit } from "../utils/Firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { Post } from "../components/blog/Post";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const BlogSingle = ({ post }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [relatedPosts, setPosts] = useState(null);
  const date = post.date.toDate();

  const getISODate = () => {
    return getFullDate(date.getDate(), date.getMonth(), date.getFullYear());
  };

  const callback = (posts) => {
    setPosts(posts);
  };

  useEffect(() => {
    if (!relatedPosts) {
      getDocsWithConditionAndLimit("articles", "tags", callback, 3, post.tags);
    } else {
      setLoading(false);
    }
  });

  const shareUrl = `https://rstraining.it${location.pathname}`;

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto">
            <article>
              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    {post.title}
                  </h1>
                  <p
                    className="text-xl italic text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    {post.description}
                  </p>
                </div>
                {/* Article meta */}
                <div className="md:flex md:items-center md:justify-between mt-3">
                  {/* Author meta */}
                  <div
                    className="flex items-center justify-center"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div>
                      <img
                        className="rounded-full flex-shrink-0 mr-4"
                        src={
                          post.writerPhoto ||
                          require("../images/news-author-04.jpg")
                        }
                        width="40"
                        height="40"
                        alt="Author 04"
                      />
                    </div>
                    <div>
                      <div className="font-medium text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out">
                        {post.writer}
                      </div>
                      {/* <span className="text-gray-700"> - </span> */}
                      <span className="text-gray-500">{getISODate()}</span>
                    </div>
                  </div>
                  {/* Article tags */}
                  <div
                    className="flex justify-center mt-4 md:mt-0"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      {post.tags.map((item, index) => {
                        return (
                          <li className="m-1" key={index}>
                            <div
                              className={`inline-flex text-center text-gray-100 py-1 px-3 rounded-full ${tagColors[item]} transition duration-150 ease-in-out`}
                            >
                              {item}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </header>

              {/* Article image */}
              {/* <figure className="mb-8 lg:-ml-32 lg:-mr-32" data-aos="fade-up" data-aos-delay="600">
                <img className="w-full" src={require('../images/news-single.jpg')} width="1024" height="576" alt="News single" />
              </figure> */}

              {/* Article content */}
              <div className="text-lg text-gray-400">
                <div className="mb-4 mt-20">
                  {post.secondaryDescription.map((item, index) => {
                    return (
                      <p
                        className="mb-4"
                        key={index}
                        data-aos="fade-up"
                        data-aos-delay="500"
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>

                {/* index of the article */}
                <p
                  className="mb-2 h4 mt-10 text-white"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  Indice dell'articolo
                </p>

                <ul
                  className="list-disc list-inside mb-20"
                  data-aos="fade-up"
                  data-aos-delay="700"
                >
                  {post.paragraphs.map((item, index) => {
                    return (
                      <li key={index} className={`${item.indent && "ml-6"}`}>
                        <ScrollLink
                          spy={true}
                          to={item.title}
                          smooth={true}
                          className="cursor-pointer hover:underline"
                        >
                          {item.title}
                        </ScrollLink>
                      </li>
                    );
                  })}
                </ul>

                {post.paragraphs.map((item, index) => {
                  return <Paragraph paragraph={item} index={index} key={index}/>;
                })}
              </div>

              {/* Article footer */}
              <footer>
                <div className="md:flex md:items-center md:justify-between text-center md:text-left">
                  <div className="text-lg text-gray-400 italic">
                    Pubblicato da {post.writer}.
                  </div>
                  <ul className="inline-flex md:ml-4 md:mb-0 md:mt-0 mt-3">
                    <li>
                      <p className="mr-4 mt-1 text-gray-400 italic ">
                        Condividi articolo:{" "}
                      </p>
                    </li>
                    <WhatsappShareButton url={shareUrl}>
                      <li>
                        <a className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                          <svg
                            className="w-8 h-8 fill-current pl-2 pt-2 flex justify-center items-center"
                            viewBox="0 0 34 34"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                          </svg>
                        </a>
                      </li>
                    </WhatsappShareButton>

                    <FacebookShareButton url={shareUrl}>
                      <li className="ml-4">
                        <a className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                          <svg
                            className="w-8 h-8 fill-current"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                          </svg>
                        </a>
                      </li>
                    </FacebookShareButton>

                    <TelegramShareButton url={shareUrl}>
                      <li className="ml-4">
                        <a className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                          <svg
                            className="w-8 h-8 fill-current pl-1 pt-1 flex justify-center items-center"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z" />
                          </svg>
                        </a>
                      </li>
                    </TelegramShareButton>

                    <TwitterShareButton url={shareUrl}>
                      <li className="ml-4">
                        <a className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                          <svg
                            className="w-8 h-8 fill-current"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                          </svg>
                        </a>
                      </li>
                    </TwitterShareButton>

                    <EmailShareButton url={shareUrl}>
                      <li className="ml-4">
                        <a className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                          <svg
                            className="w-8 h-8 fill-current pl-2 pt-2 flex justify-center items-center"
                            viewBox="0 0 36 36"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
                          </svg>
                        </a>
                      </li>
                    </EmailShareButton>
                  </ul>
                </div>
              </footer>
            </article>
          </div>

          {/* Related articles */}
          <aside className="mt-20">
            <div className="max-w-sm mx-auto md:max-w-none">
              <h4 className="h4 py-6 mb-10 border-t border-b border-gray-700">
                Altri articoli relazionati
              </h4>

              {/* Articles container */}
              <div className="grid gap-12 md:grid-cols-3 md:col-gap-6 md:row-gap-8 items-start">
                {loading ? (
                  <div className="flex justify-center items-center h-screen">
                    <ClipLoader color={"#ffffff"} loading={true} size={100} />
                  </div>
                ) : (
                  <>
                    {relatedPosts.map((item, index) => {
                      return <Post post={item} index={index} key={index}/>;
                    })}
                  </>
                )}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
