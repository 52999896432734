export const TimelineData = [
  {
    title: "The seed",
    year: "2021",
    subtitle:
      "Studente di Osteopatia presso TCIO Istituto di Osteopatia Milano",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
  },
  {
    title: "The seed",
    year: "2021",
    subtitle:
      "Laurea Magistrale (MSc) in Attività Fisica per il Benessere presso l’Università Degli Studi Di Milano",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
  },
  {
    title: "The seed",
    year: "2020",
    subtitle:
      "Tirocinio Accademico in Patologie Muscolo-Scheletriche e Metaboliche presso IRCCS Istituto Ortopedico Galeazzi",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
  },
  {
    title: "The seed",
    year: "2020",
    subtitle:
      "Divulgatore Scientifico per LaScienzaInPalestra (articoli su Biomeccanica e Fisiologia dell’esercizio)",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
  },
  {
    title: "The seed",
    year: "2019",
    subtitle:
      "Laurea Triennale (BSc) in Scienze Motorie, Sport e Salute presso l’Università Degli Studi Di Milano",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
  },
  {
    title: "The seed",
    year: "2019",
    subtitle:
      "Tirocinio Accademico in Rieducazione Posturale e Ginnastica Antalgica presso scuola Back School",
    copy: "Pretium lectus quam id leo. Urna et pharetra pharetra massa massa. Adipiscing enim eu neque aliquam vestibulum morbi blandit cursus risus.",
    last: true,
  },
];
